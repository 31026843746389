export default {
  name: "VendorsComponent",
  data() {
    return {
      heightTable: 0,
      bHeightTable: 0,
      tableData: [],
      iCurrentPage: 1,
      iItemsPerPage: 10,
      iNumPages: 0,
      iTotalItems: 0,
      screenHeight: 0,
      texts: "",
      tab: null,
    };
  },
  beforeMount() {
    this.$store.commit('setMenuSidebar', {active:false})
    this.$store.commit("setUserState", null);
    this.texts = FILE.vendorTexts[this.selectLanguage];
  },
  mounted() {
    this.fillTable();
  },
  created() {
    window.addEventListener("load",this.contentTableHeight)
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("load",this.contentTableHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    // muestra el detalle del comprador
    detailItem(id) {
      this.$router.push(`/admin/vendor-detail/${id.sVendorEnterpriseId}`);
    },
    // muestra dialogo para cambiar la contrsena del comprador
    dialogPasswordChange(item) {
      this.$store.commit("setPasswordChange", {
        api: `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${item.sVendorEnterpriseId}/password`,
        active: true,
      });
    },
    // muestra los proveedores disponibles 
    fillTable() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/vendors/enterprises`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
          sSearch: this.sSearch,
          bVerified: this.bUserState
          // tStart: this.startDate,
          // tEnd: this.endDate,
        },
      })
        .then((response) => {
          // this.tableData = response.data.results;
          this.tableData = response.data.results.map((e) => {
            return {
              ...e,
              // created_at: e.created_at.substr(0, 10),
            };
          });
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize() {
      this.screenHeight = window.innerHeight - 400;
    },
  },
  computed: {
    // set search variable for filter option
    sSearch() {
      return this.$store.state.sSearch;
    },
    bUserState() {
      return this.$store.state.bUserState;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    refreshTable: function () {
      this.fillTable();
    },
    sSearch: function () {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    bUserState() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    // endDate: function () {
    //   if (this.endDate) {
    //     this.iCurrentPage = 1;
    //     this.fillTable();
    //   }
    // },
    // startDate: function () {
    //   this.iCurrentPage = 1;
    //   this.fillTable();
    // },
    iCurrentPage: function () {
      this.fillTable();
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.vendorTexts[this.selectLanguage];
      }
      this.fillTable();
    },
  },
};